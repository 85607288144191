import React from "react"
import withPreview from "../components/context/withPreview"
import gql from "graphql-tag"
import Inspiration from "../templates/Inspiration/inspiration"

const PreviewInspiration = (props) => {
  //Format data to match '/src/templates/single/Post' format
  const data = {
    post: props?.preview?.inspirationBy?.revisions?.nodes[0], // grab the first revision
  }

  if (!!data.post) {
    return <Inspiration data={data} />
  } else {
    return null
  }
}

const PREVIEW_QUERY = gql`
  query getPreview($id: Int!) {
    inspirationBy(inspirationId: $id) {
      revisions {
        nodes {
          title
          content
          date
          tags {
            nodes {
              databaseId
            }
          }
          featuredImage {
            node {
              altText
              srcSet
            }
          }
          acfInspiration {
            authorship {
              name
              role
            }
            recipeslist {
              ... on Post {
                title
                slug
                featuredImage {
                  node {
                    altText
                    srcSet
                  }
                }
                acfRecipe {
                  difficulty
                  cookingtime {
                    hours
                    minutes
                  }
                  maintag {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(PreviewInspiration)
